.page-home{
  background-image: radial-gradient(ellipse farthest-corner at center top, #000d4d 0%, #000105 100%);
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  &-canvas{
    width: 100%;
    height: 100%;
  }

  &:root{
    --b-login-bg-start: rgba(0, 0, 0, 0);
    --b-login-bg-end: rgba(0, 0, 0, 1);
  }

  .page-home-screen-1{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image: linear-gradient(to bottom,var(--b-login-bg-start) 80%,var(--b-login-bg-end) 100%);
    .screen-1-inner{
      max-width: 1280px;
      margin: 0 auto;
      padding: 0 20px;
      .screen-1-title{
        font-size: 64px;
        font-weight: 500;
        line-height: 96px;
        letter-spacing: 2px;
        color: #FFFFFF;
        text-align: center;
      }
      .screen-1-subtitle{
        padding: 0px 0 20px;
        font-size: 32px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 52px;
        text-align: center;
      }
      .screen-1-btn{
        width: 206px;
        height: 56px;
        background: linear-gradient(42deg,#e0ce80 0%,#b5c34f 19%,#3cc46d 56%,#56c59f 76%);
        border-radius: 16px;
        font-size: 22px;
        font-weight: 400;
        color: #ededed;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 24px auto 0;
        cursor: pointer;
        letter-spacing: 8.4px;
      }
    }
  }
}
