// 头部
.common-header{
  height: var(--header-height-pc);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid var(--border-color);
  background: url('../../images/svg/header-bg.svg') no-repeat center;
  background-size: 100% 100%;
  &-left{
    .header-left-name{
      font-size: 16px;
      font-weight: 500;
    }
  }
  &-right{
    display: flex;
    align-items: center;
    .chat-more-icon{
      font-size: 20px;
      cursor: pointer;
      &:hover{
        color: var(--color-primary);
      }
    }

    .header-item{
      display: flex;
      align-items: center;
      margin-right: 10px;
      &.price-item{
        //color: #e2af52;
        color: #f1a03f;
        margin-right: 20px;
        cursor: pointer;
        .price-item-icon{
          margin-right: 4px;
          font-size: 26px;
        }
        .price-item-text{
          font-size: 14px;
        }
        .question-icon{
          //color: var(--emphasize-color);
          color: var(--gray-text-color);
          margin-left: 6px;
          font-size: 14px;
        }
      }
      &.customer-service-item{
        margin-right: 20px;
      }
    }

    .user-avatar{
      &-img{
        width: 36px;
        height: 36px;
        box-shadow: 0 2px 2px rgba(0,0,0,.075);
        //background-color: #dddddd;
        border-radius: 50%;
        cursor: pointer;
      }
    }
  }
}

.user-popover{
  padding: 10px;
  min-width: 260px;
  .user-base{
    display: flex;
    align-items: center;
    .user-avatar{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 18px;
    }
    .user-info{
      flex: 1;
      //height: 42px;
      &-username{
        font-size: 18px;
        font-weight: bold;
      }
      &-id{
        color: var(--gray-title-color);
      }
    }
  }
  .user-member{
    margin: 10px 0;

    .member-item{
      align-self: center;
      border-radius: 9px;
      font-size: 18px;
      height: 88px;
      font-weight: 700;
      padding-left: 15px;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #fff;
      .deadline{
        font-size: 12px;
        margin-top: 6px;
      }
    }

    .free{
      background-color: #868898;
    }
    .base{
      background-color: var(--color-primary);
    }
    .standard{
      background-color: #237804;
    }
    .pro{
      background-color: #ad6800;
    }
  }
  .other-setting{
    padding: 6px 10px;
    margin: 6px 0;
    cursor: pointer;
    border-radius: 4px;
    &:hover{
      background: var(--nav-active-color);
    }
  }
}

.coins-popover{
  //padding: 10px;
  min-width: 260px;
  line-height: 30px;
  &-amount{
    padding: 0 10px 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #f1a03f;
    .coins-amount-icon{
      font-size: 18px;
      margin-right: 6px;
    }
    .coins-amount-number{
      font-size: 14px;
    }
  }
  &-detail{
    border-top: 1px solid var(--border-color);
    border-bottom: 1px solid var(--border-color);
    padding: 10px 15px;
  }
  &-rules{
    padding: 10px 15px 0;
  }
  .coins-popover-rule{
    font-size: 12px;
    display: flex;
    &-text{
      margin-right: 10px;
    }
  }
}
