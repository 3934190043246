.draw-container{
  height: 100%;

  .draw-body{
    height: calc(100% - var(--header-height-pc));
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .development-icon{
      text-align: center;
      font-size: 120px;
      color: #999;
    }
    .development-icon-text{
      margin-top: 20px;
      color: #999;
      font-size: 26px;
    }
  }
}
