html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, hr, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend, input, button, select, textarea,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    box-sizing: border-box;

    padding: 0;
    margin: 0;

    font-size: 100%;
    vertical-align: baseline;

    border: none;
    outline: none;

    -webkit-tap-highlight-color: transparent;
/*-webkit-user-select: none;*/
}

html {
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
}
input, select, textarea {
    border: none;
    /*-webkit-appearance: none; // 清除内阴影*/
    -webkit-user-select: auto !important;
}

textarea {
    overflow: auto;
    resize: none;
}
h1, h2, h3, h4, h5, h6 {
    font-size: 100%;
    font-weight: normal;
}
address, caption, cite, code, dfn, th, var, i, em {
    font-style: normal;
}
abbr, acronym {
border: none;
    font-variant: normal;
}

ul, ol {
    list-style: none;
}

del {
    text-decoration: line-through;
}
ins, a {
    text-decoration: none;
}
a, img {
    -webkit-touch-callout: none;
}
img {
    border: none;
    /*display: block;*/
    vertical-align: top;
}

table {
border-spacing: 0;
    border-collapse: collapse;
}

blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
}
  sub, sup {
      position: relative;
      font-size: 75%;
      line-height: 0;
      vertical-align: baseline;
  }
sup {
    top: -0.5em;
}
sub {
    bottom: -0.25em;
}
                 input[type=number] {
                     -moz-appearance: textfield;
                 }
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
  select{
      appearance:none;
      -moz-appearance:none;
      -webkit-appearance:none;
  }
select::-ms-expand { display: none; }
