.chat-history-modal{
  padding: 0 10px 20px;
  .chat-history-header{
    display: flex;
    justify-content: center;
    align-items: center;
    .chat-history-app-avatar{
      width: 36px;
      height: 36px;
      border-radius: 4px;
      margin-right: 10px;
    }
    .chat-history-app-name{
      padding-right: 10px;
    }
  }
  .chat-history-search{
    margin: 14px 0 10px;
    &-input{}
  }
  .chat-history-body{
    display: flex;
    height: 520px;
    .chat-history-main{
      width: calc(100% - 220px);
      height: 100%;
      .chat-history-box{
        height: calc(100% - 62px);
        overflow: auto;
        padding: 10px 16px;
        background-color: var(--chat-history-bg-color);
        .chat-history-item{
          display: flex;
          margin: 5px 0;
          padding: 10px 14px;
          border-radius: 6px;
          background-color: var(--chat-history-item-bg-color);
          cursor: pointer;
          &:hover{
            background-color: var(--app-bg-color);
          }
          &-left{
            margin-right: 14px;
          }
          &-right{
            width: calc(100% - 50px);
            .chat-history-item-title{
              display: flex;
              justify-content: space-between;
              align-items: center;
              font-size: 13px;
              color: var(--gray-title-color);
            }
            .chat-history-item-content{
              white-space: pre-wrap;
            }
          }
        }

        .empty-history{
          margin-top: 80px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          font-size: 14px;
          width: 100%;
          color: var(--gray-text-color);
          .empty-icon{
            font-size: 40px;
            margin-bottom: 20px;
          }
        }
      }
    }
    .chat-history-filter{
      width: 220px;
      height: 100%;
      padding: 20px;
      &-title{
        color: var(--gray-title-color);
        margin-bottom: 20px;
      }
      &-item{
        margin-bottom: 30px;
        &-label{
          color: var(--gray-title-color);
          font-weight: bold;
          font-size: 13px;
          margin-bottom: 6px;
        }
        &-select{
          position: relative;
          background-color: var(--chat-history-bg-color);
          border-radius: 4px;
          cursor: pointer;

          &:hover{
            background-color: var(--app-active-bg-color);
          }

          &.date-select{
            display: flex;
            align-items: center;
            padding: 5px 10px;
            .date-select-label{
              color: var(--gray-title-color);
            }

            &.division-line{
              &:after{
                content: '';
                display: block;
                position: absolute;
                bottom: 0;
                left: 10px;
                right: 10px;
                height: 1px;
                background-color: var(--border-color);
              }
            }

            .date-tag{
              position: relative;
              z-index: 2;
            }

            .date-picker{
              width: 100%;
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              z-index: 1;
              opacity: 0;
              input{
                cursor: pointer;
              }
            }
          }
        }
      }
      .export-btn{}
    }
  }
}
