.page-join{
  min-width: 100%;
  height: 100%;
  .left {
    border-right: 1px solid #e2e8f0;
    //background-color: #0e0e0e;
    //background-image: radial-gradient(ellipse farthest-corner at center top, #000105 0%, #000105 100%);
    box-sizing: border-box;
    padding: 12px 16px;
    width: 100%;
    height: 100%;
    //background-color: #151a2b;
    background-color: #efefef;
    color: #111827;
    display: flex;
    justify-content: center;
    align-items: center;
    .register-box {
      width: 100%;
      .register-title{
        justify-content: flex-start;
        .login-title-text{
          font-size: 30px;
          margin-top: 30px;
          //color: #fff;
        }
      }
      .login-des{
        text-align: left;
        margin-top: 20px;
        color: #666;
      }
      .logo {
        width: 40px;
      }
      &-title {
        font-size: 30px;
        margin-top: 30px;
      }
    }
  }
  .right {
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    display: none;
    width: 50%;
    padding: 64px;
    overflow: hidden;
    flex: 1 1 auto;
    box-sizing: border-box;
    height: 100vh;
    background-image: radial-gradient(ellipse farthest-corner at center top, #000d4d 0%, #000105 100%);
    //background-color: #efefef;
    justify-content: center;
    align-items: center;
    z-index: -1;
    .bg {
      position: absolute;
      bottom: 0;
      top: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      .bgCover {
        opacity: 0.2;
        color: #64748b;
      }
    }
    .bgRight {
      opacity: 0.2;
      right: -64px;
      top: -64px;
      position: absolute;
      width: 220px;
      height: 192px;
      color: #64748b;
    }
    .join-up-box {
      max-width: 656px;
      box-sizing: border-box;
      width: 100%;
      position: relative;
      z-index: 10;
      .title {
        color: #f5f5f5;
        //color: #333;
        line-height: 1;
        font-weight: 700;
        font-size: 48px;
      }
      .desc {
        color: #bdbdbd;
        //color: #666;
        letter-spacing: -0.25px;
        line-height: 24px;
        font-size: 16px;
        margin-top: 24px;
      }
      .avatar {
        display: flex;
        align-items: center;
        margin-top: 32px;
        img{
          width: 300px;
          height: auto;
        }
        .avatarInfo {
          color: #bdbdbd;
          letter-spacing: -0.25px;
          font-weight: 500;
          margin-left: 16px;
        }
      }
    }
  }

  @media (min-width: 960px) {
    .left {
      width: 50%;
      padding: 64px;
      display: flex;
      justify-content: flex-end !important;
      align-items: center;
      box-shadow: none;
      border-radius: 0;
    }
    .right {
      display: flex;
    }
  }
}

.custom-link {
  color: #4f46e5;
  margin-left: 4px;
  text-decoration: underline;
  cursor: pointer;
}
