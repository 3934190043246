.bot-container{
  height: 100%;
  .bot-body{
    height: calc(100% - var(--header-height-pc));
    padding: 20px;
    position: relative;

    $app-search-bar-height: 60px;

    .app-search-bar{
      height: $app-search-bar-height;
      display: flex;
      align-content: center;
      .search-tags{
        display: flex;
        align-items: center;
        margin-right: 20px;
        .search-tag{
          margin-right: 10px;
        }
      }
      .search-input-box{
        display: flex;
        align-items: center;
        .search-input{
          //width: 100px;
          width: 400px;
        }
      }
    }
    .bot-list{
      height: calc(100% - #{$app-search-bar-height});
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      overflow: auto;
      .bot-item{
        background-color: var(--app-bg-color);
        border-radius: 10px;
        padding: 20px 15px;
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 18.4%;
        box-shadow: 0 0 2px 0 #e2e2e2;
        margin: 10px 0.8%;
        cursor: pointer;
        &:hover{
          background-color: var(--app-active-bg-color);
        }
        &-avatar {
          margin-bottom: 20px;
          .bot-img {
            width: 90px;
            height: 90px;
            border-radius: 50%;
          }
        }
        &-content{
          width: 100%;
          .bot-title{
            font-size: 16px;
            font-weight: bold;
            text-align: center;
          }
          .bot-description{
            margin: 10px 0;
            height: 62px;
            width: 100%;
            font-size: 13px;
            color: var(--gray-title-color);
          }
          .bot-other{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .app-tags{}
            .app-action{}
          }
        }
      }

      .bot-empty{
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        width: 100%;
        color: var(--gray-text-color);
        .empty-icon{
          font-size: 40px;
          margin-bottom: 20px;
        }
      }
    }

    .bot-app-detail{
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: lightcyan;
    }
  }
}
