.recharge-container{
  height: 100%;
  .recharge-body{
    height: calc(100% - var(--header-height-pc));
    overflow: auto;
    .recharge-title{
      font-size: 40px;
      font-weight: 700;
      height: 140px;
      line-height: 140px;
      text-align: center;
    }
    .stick-tab{
      padding: 20px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      //background-color: var(--page-basic-color);
      background-color: var(--bg-color);
      &-inner{
        background: var(--recharge-card-bg-color);
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 560px;
        height: 60px;
        z-index: 2;
        padding: 0 12px;
        .tab-item{
          align-items: center;
          color: #888;
          cursor: pointer;
          display: flex;
          flex-direction: row;
          font-size: 18px;
          font-weight: 400;
          height: 46px;
          justify-content: center;
          width: 44%;
          .price-tip{
            color: #fdc25e;
            font-size: 14px;
            font-weight: 700;
            margin-left: 4px;
          }
          &-active{
            background: linear-gradient(-45deg,#7d64f9,#3996cf);
            border-radius: 30px;
            color: #fff;
            font-size: 18px;
            font-weight: 700;
            height: 46px;
          }
        }
      }
    }
    .recharge-content{
      display: flex;
      flex-wrap: wrap;
      margin-left: 20px;
      margin-top: 50px;
      width: calc(100% - 20px);

      // 套餐
      .price-card{
        width: calc(25% - 20px);
        @media screen and (max-width: 1400px) and (min-width: 1000px){
          width: calc(33.3% - 20px);
        }
        @media screen and (max-width: 1000px){
          width: calc(50% - 20px);
        }
        position: relative;
        background-color: red;
        margin-right: 20px;
        margin-bottom: 20px;
        border-radius: 10px;
        &-content{
          background-color: var(--recharge-card-bg-color);
          border-radius: 10px;
          height: 100%;
          padding: 40px 30px;
          z-index: 2;
          position: relative;
          .price-card-header{
            position: relative;
            &-title{
              font-size: 26px;
              font-weight: bold;
              color: var(--color-primary);
            }
            &-price{
              margin: 10px 0;
              display: flex;
              align-items: flex-end;
              span{
                margin: 0 2px;
                font-size: 18px;
              }
              .current-price{
                font-size: 40px;
                margin: 0 4px;
                font-weight: bold;
                transform: translateY(10px);
              }
              .original-price{
                color: #999;
                text-decoration: line-through;
              }
              &.free{
                font-size: 40px;
                font-weight: bold;
              }

              .price-right{
                display: flex;
                flex-direction: column;
                font-size: 12px;
                span{
                  font-size: 12px;
                }
                &-base{
                  display: flex;
                }
                &-total{
                  // margin-top: 10px;
                }
              }
            }
            &-btn{
              width: 100%;
              margin-bottom: 10px;
              &.free-btn{
                background-color: var(--recharge-card-bg-color);
                border-color: #999999;
                &:hover{
                  color: #888;
                }
              }
              &.standard-btn{
                background-color: #389e0d;
                border-color: #389e0d;
                color: #fff;
                &:hover{
                  background-color: #237804;
                }
              }
              &.pro-btn{
                background-color: #d48806;
                border-color: #d48806;
                color: #fff;
                &:hover{
                  background-color: #ad6800;
                }
              }
              &:hover{
                color: #fff;
              }
            }
            .cancel-tip{
              color: #b4b4b4;
              font-size: 12px;
              text-align: center;
            }
            .recommend-item{
              position: absolute;
              top: 10px;
              right: -10px;
              padding: 2px 12px;
            }
          }
          .price-card-body{
            .get-info{
              margin-bottom: 10px;
              &-title{
                font-size: 12px;
                color: #888;
                margin-bottom: 12px;
              }
              &-item{
                display: flex;
                align-items: center;
                margin: 8px 0;
                &-icon{
                  margin-right: 8px;
                  font-size: 16px;
                  color: var(--color-primary);
                  background-color: white;
                  width: 16px;
                  height: 16px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  border-radius: 50%;
                }
                &-value{
                  //color: #aaa;
                  color: var(--gray-title-color);
                  display: flex;
                  align-items: center;
                  white-space: nowrap;
                  .tip-icon{
                    margin-left: 8px;
                  }
                }
                .emphasize-color{
                  color: var(--emphasize-color);
                }
              }
            }
          }

          .circle-text{
            color: #a3abe0;
            border: 1px solid #a3abe0;
            border-radius: 14px;
            padding: 0 8px;
            margin-left: 8px;
            font-size: 14px;
          }
        }
        &-border{
          background: linear-gradient(-45deg,#7d64f9,#3996cf);
          bottom: -3px;
          left: -3px;
          position: absolute;
          right: -3px;
          top: -3px;
          z-index: 1;
          display: none;
          border-radius: 10px;
        }

        &-active{
          .price-card-border{
            display: block;
          }
        }
      }

      // 金币购买
      .coin-pay-view{
        display: flex;
        align-items: center;
        background-color: var(--recharge-card-bg-color);
        border-radius: 12px;
        flex-direction: column;
        margin: 0 auto 80px;
        width: 100%;
        transform: translateX(-10px);
        padding: 30px 10px 50px;
        max-width: 900px;
        .coin-pay-title{
          align-items: center;
          display: flex;
          font-size: 26px;
          font-weight: 700;
          justify-content: center;
          margin-bottom: 6px;
          width: 100%;
        }
        .coin-pay-select{
          border-radius: 8px;
          cursor: pointer;
          height: 60px;
          margin-top: 20px;
          padding: 2px;
          width: 500px;
          &.custom-select{
            height: auto;
          }

          &-inner{
            align-items: center;
            background-color: var(--recharge-card-bg-color);
            border: 1px solid #909192;
            border-radius: 8px;
            display: flex;
            flex-direction: row;
            height: 100%;
            justify-content: space-between;
            padding-left: 14px;
            padding-right: 14px;
            width: 100%;
            font-size: 16px;
            .coin-pay-select-left{}
            .coin-pay-select-right{
              display: flex;
              align-items: center;
              .coin-icon{
                margin-right: 6px;
                font-size: 20px;
              }
            }

            .coin-pay-select-custom{
              width: 100%;
              .custom-title{
                font-size: 20px;
                font-weight: 700;
                line-height: 56px;
              }
              .custom-sub-title {
                color: #b4b4b4;
                font-size: 12px;
                display: none;
              }
              .custom-input-warp{
                display: none;
                align-items: center;
                justify-content: space-between;
                margin: 10px 0 20px;
                .custom-input{
                  margin: 0 10px;
                  .coin-icon{
                    font-size: 20px;
                  }
                }
              }
            }
          }

          &.coin-pay-select-active{
            background: linear-gradient(-45deg,#7d64f9,#3996cf);
            .coin-pay-select-custom{
              .custom-sub-title{
                display: block;
              }
              .custom-input-warp{
                display: flex;
              }
            }
          }
        }
        .coin-pay-price{
          color: #f1a03f;
          font-size: 16px;
          font-weight: 700;
          margin-top: 50px;
          .link-text{
            margin-left: 10px;
          }
        }
        .coin-pay-btn{
          margin-top: 40px;
          width: 300px;
        }
      }
      .coin-pay-un-auth{
        width: 100%;
        text-align: center;
        font-size: 18px;
        .link-text{
          margin-left: 8px;
        }
      }
    }
  }
}

.order-prepare-modal{
  padding: 0 20px 20px;
  .order-prepare-title{
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin-bottom: 10px;
    .title-name{
      font-weight: bold;
      margin-right: 15px;
      font-size: 18px;
    }
    .title-price{
      color: var(--color-primary);
      span{
        font-size: 40px;
        font-weight: bold;
      }
    }
  }
  .empty-channel{
    margin: 100px 0 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 22px;
  }
  .success-order{
    .success-order-info{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 40px 0 30px;
      .success-order-icon{
        font-size: 50px;
      }
      .success-order-text{
        font-size: 20px;
        margin-top: 20px;
        font-weight: bold;
      }
    }
    .success-order-no{
      text-align: center;
      font-size: 18px;
      margin-bottom: 50px;
    }
    .success-order-btns{
      display: flex;
      justify-content: center;
      .success-order-btn{
        margin: 0 10px;
      }
    }
  }
  .order-prepare-des{
    margin: 10px 0;
    font-size: 14px;
    text-align: center;
    .des-item{
      .question-icon{
        margin: 0 8px;
        cursor: pointer;
      }
    }
    .large-font{
      font-size: 18px;
      font-weight: bold;
    }
  }
  .order-prepare-pay-type{
    .pay-type-tab-label{
      display: flex;
      justify-content: center;
      align-content: center;
      padding: 0 10px;
    }
    .pay-type-icon{
      font-size: 20px;
      margin-right: 8px !important;
    }
  }
  .order-prepare-pay-channels{
    display: flex;
    justify-content: center;
    align-content: flex-start;
    flex-wrap: wrap;
    .channel-item{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin: 10px;
    }
  }
  .tips{
    text-align: center;
    line-height: 40px;
    color: var(--gray-text-color);
  }
  .channel-qrcode{
    margin: 40px 0 10px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .qrcode-count-down{
      position: absolute;
      top: -36px;
      left: 50%;
      transform: translateX(-50%);
      font-size: 12px;
      .success-color{
        font-size: 20px;
        font-weight: bold;
      }
    }
    .ant-qrcode-scanned{
      display: none;
    }
    .empty-box{
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      .empty-text{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 90;
      }
    }
  }
  .channel-qrcode-tip{
    text-align: center;
    .tip{
      font-size: 12px;
      margin-top: 30px;
    }
  }
  .show-order-no{
    margin: 20px 0 10px;
    text-align: center;
    font-size: 18px;
  }
  .dev-action{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    .dev-btn{
      margin: 0 10px;
    }
  }
}
