// 这里定义一些全局变量
body{
  --link-color: #7580cc;
  //--link-color: #3693B3;
  //--color-primary: #3693B3;
  --color-primary: #7580cc;
  --page-basic-color: #f8f9fa;
  --nav-width-pc: 70px;
  --chat-bar-width-pc: 250px;
  --header-height-pc: 60px;
  --chat-input-height-pc: 200px;

  // ------- 不一样的 ---------
  --page-basic-color: #f8f9fa;
  --bg-color: #FFF;
  --nav-bg-color: #fff;
  --nav-active-color: #eeeeee;
  --border-color: #dee2e6;
  --gray-text-color: #aaa;
  --gray-title-color: #888;

  // chat
  --chat-bar-active-color: #f2f3f5;
  --chat-bg-color: #f2f3f5;
  --chat-list-bg-color: #fff;
  --chat-tab-active-color: #dddedd;
  --chat-tab-icon-active-color: #000;
  --chat-content-bg-color: #f2f3f5;
  --chat-content-bg-error-color: #fff1f0;
  --chat-content-self-bg-color: #d4edda;
  --chat-footer-border-color: #ddd;
  --chat-footer-bg-color: #fff;
  --chat-history-bg-color: #fff; // 聊天历史背景颜色
  --chat-history-item-bg-color: #f2f3f5; // 聊天历史每一项背景颜色

  // recharge
  --recharge-card-bg-color: #f6f6f6;
  --emphasize-color: #000;

  // app
  --app-bg-color: #f2f3f5;
  --app-active-bg-color: #e2e3e5;

  // 会员
  --member-introduce-bg-color: #efefef;
}

.dark-theme{
  --page-basic-color: #000;
  --bg-color: #000; // 背景颜色
  --nav-bg-color: #0e0e0e; // 左侧导航背景颜色
  --nav-active-color: #292b2f; // 左侧导航激活颜色
  --border-color: #292b2f; // 边框颜色
  --gray-text-color: #666;
  --gray-title-color: #999;

  // chat
  --chat-bar-active-color: #292b2f; // 好友列表激活颜色
  --chat-bg-color: #0e0e0e; // 聊天背景颜色
  --chat-list-bg-color: #0e0e0e; // 聊天窗口颜色
  --caht-tab-active-color: #666; // tab激活边框颜色
  --chat-tab-icon-active-color: #fff; // tab操作icon激活颜色
  --chat-content-bg-color: #292b2f; // 聊天消息背景色
  --chat-content-bg-error-color: #2f1515;
  --chat-content-self-bg-color: #57ac6e; // 聊天我发的消息内容背景色
  --chat-footer-border-color: #292b2f; // 聊天底部边框颜色
  --chat-footer-bg-color: #101010; // 聊天底部背景颜色
  --chat-history-bg-color: #141414; // 聊天历史背景颜色
  --chat-history-item-bg-color: #1f1f1f; // 聊天历史每一项背景颜色

  // recharge
  --recharge-card-bg-color: #2a2c2e;
  --emphasize-color: #fff;

  // app
  --app-bg-color: #292b2f;
  --app-active-bg-color: #393b3f;

  // 会员
  --member-introduce-bg-color: #2a2c2e;
}

// 布局结构
.layout{
  width: 100%;
  height: 100%;
  display: flex;
  box-shadow: 0 2px 2px rgba(0,0,0,.075);
  background-color: var(--bg-color);
  min-width: 760px;
  .navbar{
    width: var(--nav-width-pc);
    height: 100%;
    background: var(--nav-bg-color);
    transition: width 0.3s;
    overflow: hidden;
  }
  .main{
    width: calc(100% - var(--nav-width-pc));
    height: 100%;
  }
}
