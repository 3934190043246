.market-container{
  height: 100%;
  .market-body{
    height: calc(100% - var(--header-height-pc));
    padding: 20px;
    position: relative;

    $app-search-bar-height: 60px;

    .app-search-bar{
      height: $app-search-bar-height;
      display: flex;
      align-content: center;
      .search-tags{
        display: flex;
        align-items: center;
        margin-right: 20px;
        .search-tag{
          margin-right: 10px;
        }
      }
      .search-input-box{
        display: flex;
        align-items: center;
        .search-input{
          //width: 100px;
          width: 400px;
        }
      }
    }
    .app-list{
      height: calc(100% - #{$app-search-bar-height});
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      overflow: auto;
      .app-item{
        background-color: var(--app-bg-color);
        border-radius: 10px;
        padding: 15px 12px;
        display: flex;
        align-items: center;
        width: 31.3%;
        height: 150px;
        box-shadow: 0 0 2px 0 #e2e2e2;
        margin: 20px 1% 0;
        cursor: pointer;
        &:hover{
          background-color: var(--app-active-bg-color);
        }
        &-avatar {
          margin-right: 20px;
          .app-img {
            width: 76px;
            height: 76px;
            border-radius: 5px;
          }
        }
        &-content{
          width: calc(100% - 80px);
          .app-title{
            font-size: 18px;
            font-weight: bold;
          }
          .app-des{
            //color: #eee;
            margin: 10px 0;
            height: 44px;
          }
          .app-other{
            display: flex;
            justify-content: space-between;
            align-items: center;
            .app-tags{}
            .app-action{}
          }
        }
      }

      .app-empty{
        margin-top: 60px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        width: 100%;
        color: var(--gray-text-color);
        .app-empty-icon{
          font-size: 40px;
          margin-bottom: 20px;
        }
      }
    }

    .form-app-detail{
      transition: all .3s;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      background-color: var(--page-basic-color);
      opacity: 0;
      z-index: -1;
      visibility: hidden;
      overflow: auto;
      &.is-show{
        opacity: 1;
        z-index: 1;
        visibility: visible;
      }

      &-inner{
        padding: 20px 80px;
        max-width: 800px;
        position: relative;
        margin: 0 auto;
        .detail-back{
          position: absolute;
          top: 20px;
          left: 20px;
          .detail-back-icon{
            font-size: 26px;
            transition: transform .3s;
            cursor: pointer;
            &:hover{
              transform: scale(1.2);
              color: var(--color-primary);
            }
          }
        }

        .detail-title{
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 10px 0 20px;
          .avatar-img{
            width: 40px;
            height: 40px;
            border-radius: 5px;
            margin-right: 8px;
          }
          .title-text{
            font-size: 24px;
            font-weight: bold;
          }
        }
        .detail-description{
          text-align: center;
          line-height: 28px;
        }
        .detail-greeting{
          color: var(--gray-text-color);
          margin: 20px 0;
        }
        .detail-command-greeting{
          color: var(--gray-text-color);
          margin-bottom: 8px;
        }
        .form-app-input-textarea{
          //height: 110px;
          //resize: none;
        }
        .form-btn-list{
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 20px;
          .form-btn{
            margin-right: 10px;
          }

          .left{}
          .right{
            display: flex;
            align-items: center;
            justify-content: flex-end;
          }

        }
        .detail-commands{
          display: flex;
          flex-wrap: wrap;
          align-content: flex-start;
          margin-bottom: 20px;
          .detail-command{
            background-color: var(--app-bg-color);
            padding: 6px 16px;
            margin: 10px;
            border-radius: 5px;
            cursor: pointer;
            &:hover{
              background-color: var(--app-active-bg-color);
            }
          }
        }
        .detail-output-box{
          margin: 30px 0;
          .output-title{
            color: var(--gray-text-color);
            margin-bottom: 10px;
          }
          .output-text{
            padding: 10px 11px;
            margin: 0 8px;
            border-radius: 4px;
            background-color: var(--chat-content-bg-color);
            word-break: break-word;
            pre{
              white-space: pre-wrap;
            }
            &.output-text-error{
              background-color: var(--chat-content-bg-error-color);
            }
          }
        }
      }
    }
  }
}
