.navbar-inner{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  border-right: 1px solid var(--border-color);
  position: relative;
  &-top{
    width: 100%;
    padding-top: 20px;
    .user-avatar{
      width: 40px;
      height: 40px;
      box-shadow: 0 2px 2px rgba(0,0,0,.075);
      background-color: #dddddd;
      border-radius: 50%;
      cursor: pointer;
    }
  }

  &-bottom{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-float {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%) rotate(180deg) scale(0.7, 1.1);
    transition: all ;
    //z-index: -99;
    z-index: 1;
    font-size: 22px;
    cursor: pointer;
    border-radius: 4px;
    &:hover{
      background-color: var(--color-primary);
      color: #fff;
    }
  }

  &:hover{
    .navbar-inner-float{
      z-index: 1;
    }
  }

  .navbar-item{
    width: 100%;
    display: flex;
    //justify-content: center;
    align-items: center;
    font-size: 26px;
    padding: 8px;
    cursor: pointer;
    position: relative;
    overflow: hidden;

    .navbar-item-select{
      position: absolute;
      top: 8px;
      right: 10px;
      bottom: 8px;
      left: 10px;
      z-index: 1;
      border-radius: 10px;
    }

    &:hover{
      .navbar-item-select{
        background-color: var(--nav-active-color);
      }
    }

    &-icon{
      padding: 0 14px;
      border-radius: 4px;
      position: relative;
      z-index: 2;
      //&:hover{
      //  background: var(--nav-active-color);
      //}
      .toggle-icon{
        //font-size: 20px;
      }
      &.theme-icon{
        //font-size: 18px;
      }
    }
    &-text {
      font-size: 15px;
      position: relative;
      z-index: 2;
      transition: all ;
      white-space: nowrap;
      padding: 0 8px 0 0px;
    }

    &.active-item{
      .navbar-item-icon{
        svg{
          color: #fff;
        }
      }
      .navbar-item-text{
        color: #fff;
      }

      .navbar-item-select{
        background-color: var(--color-primary);
      }
    }
  }
}
