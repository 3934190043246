
.user-container{
  width: 100%;
  height: 100%;

  // 主体
  .user-body{
    background-color: var(--page-basic-color);
    height: calc(100% - var(--header-height-pc));
    overflow: auto;

    .person-container{
      height: 100%;
      overflow: auto;
      padding: 0 40px;

      .navs{
        display: flex;
        justify-content: center;
        align-items: center;
        height: 80px;
        padding-top: 20px;
        padding-bottom: 10px;
        .nav{
          align-items: center;
          //background: #5f6277;
          background: rgba(100, 180, 255, 0.12);
          border-radius: 10px;
          cursor: pointer;
          display: flex;
          font-size: 16px;
          justify-content: center;
          margin: 0 6px;
          padding: 6px 24px;
          transition: all .3s ease;
          color: var(--emphasize-color);

          &-active, &:hover{
            background: #7280d2;
            border-color: #7280d2;
            color: #fff;
          }
        }
      }

      .person-body{
        padding: 10px 20px 60px;
        height: calc(100% - 80px);
        overflow: auto;
        // 我的主页
        .account-box{
          margin: 0 auto;
          max-width: 700px;
          padding-top: 60px;

          &-header{
            display: flex;
            align-items: center;
            padding-left: 18px;
            margin-bottom: 30px;
            .account-avatar{
              width: 100px;
              height: 100px;
              border-radius: 50%;
              margin-right: 20px;
            }
            .account-info{
              position: relative;
              &-username{
                font-weight: bold;
                font-size: 20px;
              }
              &-id{
                color: var(--gray-text-color);
                margin: 6px 0;
                font-size: 13px;
              }
              &-nickname{
                color: var(--gray-text-color);
                font-size: 13px;
              }
              .edit-btn{
                position: absolute;
                top: 2px;
                right: -150px;
              }
            }
          }
          .account-edit-item{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 20px;
            margin-top: 10px;
            border-bottom: 1px solid var(--border-color);
            &-left{
              .left-label{
                font-weight: bold;
                font-size: 18px;
              }
              .left-value{
                margin-top: 6px;
                font-size: 13px;
                color: var(--gray-text-color);
              }
              .empty-text{}
            }
            &-right{
              .edit-item-right-btn{}
            }
          }
          .account-operation{
            margin-top: 40px;
            display: flex;
            align-items: center;
            &-btn{
              margin: 0 12px;
            }
          }
        }
        // 会员信息
        .member-box{
          padding: 20px 80px 60px;
          .member-header{
            display: flex;
            .member-info{
              width: 300px;
              .info-title{
                font-size: 22px;
                font-weight: 700;
              }
              .next-time{
                color: var(--gray-title-color);
                font-size: .75rem;
                margin-top: 10px;
              }
              .coins-amount{
                display: flex;
                justify-content: space-between;
                margin-top: 20px;
                width: 100%;
                .coins-amount-item{
                  .item-top{
                    color: #f1a03f;
                    display: flex;
                    align-items: center;
                    .coins-icon{
                      font-size: 26px;
                      margin-right: 6px;
                    }
                    .coins-number{
                      font-size: 18px;
                      margin-top: 4px;
                    }
                  }
                  .item-bottom{
                    font-size: 12px;
                    color: var(--gray-title-color);
                  }
                }
                .plus{
                  font-size: 30px;
                  font-weight: bold;
                }
              }
              .level-info{
                display: flex;
                margin-top: 40px;
                align-items: baseline;
                .level-name{
                  font-size: 24px;
                  font-weight: 700;
                  margin-right: 10px;
                }
                .look-all{}
              }
              .user-deadline{
                font-size: 12px;
                margin-top: 6px;
              }
              .update-btn{
                margin-top: 40px;
                width: 160px;
              }
            }
            .coins-detail{
              width: 340px;
              height: 330px;
              border: 1px solid var(--border-color);
              border-radius: 6px;
              margin-left: 100px;
              padding: 20px;
              &-title{
                font-size: 18px;
                font-weight: 700;
                //display: flex;
                //justify-content: space-between;
                //align-items: center;
              }
              &-list{
                margin-top: 10px;
                padding-right: 10px;
                height: calc(100% - 35px);
                overflow-y: auto;
                .coins-detail-item{
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  margin: 10px 0;
                  &-label{}
                  &-value{}
                }
                .empty-coins-detail{
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  padding: 100px 10px 0;
                  font-size: 14px;
                  color: var(--gray-text-color);
                }
              }
            }
          }
          .earn-coins{
            margin-top: 60px;
            &-title{
              font-size: 24px;
              font-weight: 700;
            }
            &-des{
              font-size: 14px;
              margin-top: 12px;
              color: var(--gray-title-color);
            }
            &-source {
              display: flex;
              margin-top: 18px;
              .earn-item{
                align-items: center;
                background-color: var(--member-introduce-bg-color);
                border-radius: 6px;
                display: flex;
                flex-direction: column;
                height: 240px;
                justify-content: center;
                margin-right: 10px;
                width: 1802px;
                padding: 20px;
                &-icon{
                  font-size: 22px;
                }
                &-title{
                  font-size: 18px;
                  font-weight: 700;
                  margin-top: 14px;
                  color: var(--emphasize-color);
                }
                &-des{
                  margin-top: 6px;
                  text-align: center;
                  height: 36px;
                  color: var(--gray-title-color);
                }
                &-btn{
                  margin-top: 20px;
                  width: 120px;
                }
              }
            }
          }
          .earn-coins-other{
            margin-top: 40px;
            &-title{
              font-size: 18px;
              font-weight: 700;
            }
            &-item{
              display: flex;
              align-items: center;
              margin-top: 18px;
              .other-item-price{
                align-items: center;
                display: flex;
                width: 100px;
                &-icon{
                  font-size: 20px;
                }
                &-text{
                  color: #f1a03f;
                  font-size: 14px;
                  margin-left: 5px;
                }
              }
              .other-item-content{
                margin-left: 80px;
                width: 320px;
                color: var(--gray-title-color);
              }
              .other-item-max{}
            }
          }
          .split-line{
            margin: 80px 0;
            height: 1px;
            background-color: var(--border-color);
          }
        }
        // 我的邀请
        .invite-box{
          padding: 0 80px;
          .invite-header{
            display: flex;
            align-items: center;
            border-bottom: 1px solid var(--border-color);
            font-size: 14px;
            font-weight: 400;
            padding: 20px 20px 25px;
            &-text{
              margin-right: 10px;
            }
          }
          .invite-section{
            margin-top: 50px;
            padding: 0 20px;
            &-title{
              font-size: 18px;
              font-weight: 700;
              margin-top: 30px;
              margin-bottom: 10px;
            }
            &-des{
              color: #b9b9b9;
              font-size: 14px;
              font-weight: 400;
            }
            .invite-referral{
              display: flex;
              height: 40px;
              align-items: center;
              margin-top: 30px;
              .invite-url{
                align-items: center;
                border: 1px solid #6a6969;
                border-radius: 8px 8px 8px 8px;
                color: #b9b9b9;
                display: flex;
                font-size: 14px;
                font-weight: 400;
                height: 100%;
                padding: 0 15px;
                word-break: break-all;
              }
              .invite-url-btn{
                margin-left: 14px;
              }
            }

            .invite-data{
              display: flex;
              align-items: center;
              padding: 10px 0;
              &-item{
                display: flex;
                align-items: center;
                margin-right: 40px;
                &-label{
                  color: #b9b9b9;
                }
                &-value{
                  font-weight: bold;
                  font-size: 18px;
                  margin-left: 10px;
                  min-width: 100px;
                }
              }
            }
          }
          .more-rewards{
            margin-top: 80px;
            padding: 0 20px;
            .more-rewards-title{
              font-size: 18px;
              font-weight: 700;
              margin-top: 30px;
              margin-bottom: 10px;
            }
            .all-rewards{
              grid-gap: 10px;
              display: grid;
              gap: 10px;
              grid-template-columns: repeat(auto-fit,200px);
              margin-top: 30px;
              .reward-item{
                background: #1a1a1a;
                border-radius: 16px;
                display: flex;
                flex-direction: column;
                height: 160px;
                justify-content: center;
                margin-right: 15px;
                padding: 0 10px;
                width: 200px;
                .one-reward{
                  font-size: 18px;
                  font-weight: 700;
                  opacity: .4;
                }
                .two-reward{
                  text-align: center;
                }
                .three-reward{
                  display: flex;
                  font-size: 12px;
                  font-weight: 400;
                  justify-content: center;
                  margin-top: 15px;
                  text-align: center;
                }
                .four-reward{
                  font-size: 14px;
                  font-weight: 700;
                  margin-top: 10px;
                  text-align: center;
                  color: var(--color-primary);
                }
              }
            }
          }
        }
        // 支付历史
        .payment-box{
          .payment-table{
            padding: 20px 80px;
          }
          .payment-empty{
            margin-top: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            font-size: 16px;
          }
        }
      }
    }
  }
}

.un-auth-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .un-auth-icon{
    font-size: 160px;
    margin-top: -120px;
  }
  .un-auth-text{
    margin: 20px 0 40px;
    font-size: 22px;
    color: #999999;
  }
  .un-auth-btn{
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.coin-logs-modal{
  //max-height: 700px;
  //overflow: auto;
}
