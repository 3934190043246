.form-modal{
  padding: 40px 20px 30px;
  .form-sub-header{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    &-left{
      font-size: 16px;
      font-weight: bold;
      padding-left: 10px;
      color: #999;
      .form-sub-title{
        margin-left: 10px;
      }
    }
    &-right{}
  }
  .form-item{
    margin-bottom: 24px;
  }
  .form-item-phone{
    .form-item-phone-select{
      margin-right: 10px;
      width: 100px;
    }
    display: flex;
  }
  .form-item-code{
    display: flex;
    .form-item-code-btn{
      margin-left: 10px;
    }
  }
  .form-btn{
    width: 100%;
  }
}


.large-modal-inner{
  height: 100%;
  .modal-content{
    overflow: auto;
    height: calc(100% - 50px);
    padding-right: 8px;
  }
  .modal-footer{
    height: 40px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 10px;
  }

  ._markdown-body{
    font-size: 14px;
    line-height: 20px;
    word-spacing: 0;
    letter-spacing: 0;
    word-break: break-word;
    word-wrap: break-word;
    text-align: left;
    .paragraph{
      padding: 4px 0;
    }
  }
}

// 登录
.login-container{
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 20px 60px;
  .login-box{
    //margin-top: -80px;
    width: 500px;
  }
  .login-header{
    .login-title{
      display: flex;
      justify-content: center;
      align-items: center;
      line-height: 44px;
      height: 44px;
      &-logo{
        margin-right: 10px;
        width: 40px;
        height: 40px;
      }
      &-text{
        font-size: 20px;
        font-weight: bold;
      }
    }
    .login-des{
      text-align: center;
      margin-block-start: 10px;
      margin-block-end: 30px;
      color: #999;
      font-size: 14px;
    }
  }
  .login-body{
    .form-container{
      padding-top: 10px;
      position: relative;
      .form-sub-header{
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        line-height: 32px;
        &-left{
          font-size: 16px;
          font-weight: bold;
          padding-left: 10px;
          color: #999;
          .form-sub-title{
            margin-left: 10px;
          }
        }
        &-right{}
      }
      .form-item-phone{
        .form-item-phone-select{
          margin-right: 10px;
          width: 100px;
        }
        display: flex;
      }
      .form-item-code{
        display: flex;
        .form-item-code-btn{
          margin-left: 10px;
        }
      }
      .form-btn{
        width: 100%;
      }
      .form-mark{
        margin: -10px 0 30px;
        font-size: 14px;
        color: #666;
        .link-text{
          font-weight: bold;
          margin: 0 4px;
        }
      }
      .form-tip{
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 32px;
        &-left{
          display: flex;
          align-items: center;
          .other-auth-type{
            display: flex;
            align-items: center;
          }
          .auth-type-icon{
            width: 26px;
            height: 26px;
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            font-weight: bold;
            color: #fff;
            cursor: pointer;
            margin-left: 10px;
            font-size: 26px;
            transition: all .3s;
            &:hover{
              transform: scale(1.2);
            }
          }
        }

        &-right{
          .forget-password-tip{
            display: flex;
            justify-content: flex-end;
          }
        }
      }
    }
  }
}

.change-user-info-modal{
  padding: 40px 20px 30px;
  .person-avatar{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    &-img{}
    &-btn{
      margin-top: 10px;
    }
  }
  .person-edit-item{
    display: flex;
    align-items: center;
    //border: 1px solid red;
    border-radius: 4px;
    padding: 2px 10px;
    margin: 14px 0;
    &-label{
      width: 60px;
      margin-right: 5px;
      font-size: 16px;
      &.change-label{
        margin-left: 11px;
      }
    }
    &-value{
      flex: 1;
      min-width: 250px;
    }
  }
  .person-operation{
    margin-top: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    &-btn{
      margin: 0 12px;
    }
  }
}
