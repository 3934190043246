html, body{
  height: 100%;
}

#root{
  height: 100%;
  font-family: -apple-system,BlinkMacSystemFont,'Segoe UI',Roboto,'Helvetica Neue',Arial,'Noto Sans',sans-serif,'Apple Color Emoji','Segoe UI Emoji','Segoe UI Symbol','Noto Color Emoji';
}

.config-app{
  width: 100%;
  height: 100%;
}

.ellipsis{
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ellipsis-2{
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.ellipsis-3{
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

.primary-color{
  color: var(--color-primary);
}

.success-color{
  color: #3cc46d;
}
.gray-color{
  color: #bbb;
}
.warning-color{
  color: #ce9938;
}
.error-color{
  color: #dc4446;
}
