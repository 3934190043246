.chat-container{
  height: 100%;
  display: flex;
  .chat-bar{
    width: var(--chat-bar-width-pc);
    height: 100%;
    border-right: 1px solid var(--border-color);
    transition: all 0.3s;
    overflow: hidden;
    .chat-search-bar{
      height: var(--header-height-pc);
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 10px 20px;
      .add-btn{
        width: 100%;
        //margin-bottom: 10px;
        //width: 50px;
      }
    }
    .chat-group{
      height: calc(100% - var(--header-height-pc));
      overflow: auto;
      padding-bottom: 15px;
      &::-webkit-scrollbar {
        width: 0;
        height: 0;
        scrollbar-width: none;
        scrollbar-height: none;
      }
      .chat-group-item{
        padding: 9px 12px;
        display: flex;
        justify-content: space-between;
        cursor: pointer;
        position: relative;
        &:hover{
          background-color: var(--chat-bar-active-color);
        }
        &-avatar{
          position: relative;
          .avatar-is-top{
            position: absolute;
            top: 0;
            left: 0;
            font-size: 30px;
          }
        }
        &-main{
          width: calc(100% - 42px);
          padding: 0 5px;
          overflow: hidden;
          position: relative;
          .chat-group-item-title{
            display: flex;
            .chat-group-item-name{
              flex: 1;
              line-height: 22px;
              height: 22px;
              margin-right: 4px;
            }
            .chat-group-item-time{
              font-size: 12px;
              color: var(--gray-text-color);
              transform: translateX(6px);
            }
          }
          .chat-group-item-description{
            font-size: 13px;
            color: #6c757d;
            line-height: 20px;
            height: 20px;
          }
          .group-item-action{
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 30%;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            &:hover{
              .action-btn{
                display: block;
              }
            }
            .action-btn{
              display: none;
              &.action-btn-active{
                display: block;
              }
            }
            //.more-action-icon{
            //  padding: 10px;
            //  border-radius: 10px;
            //  margin-right: 10px;
            //  background-color: lightseagreen;
            //}
          }
        }

        &.active-chat-group{
          background-color: var(--chat-bar-active-color);
        }
      }
    }
  }
  .chat-box{
    width: calc(100% - var(--chat-bar-width-pc));
    transition: width 0.3s;
    height: 100%;

    $action-offset: -60px;

    .chat-body{
      height: calc(100% - var(--header-height-pc));
      overflow-x: hidden;
      background-color: var(--chat-bg-color);
      padding-top: 3px;
      display: flex;
      &-left{
        width: 100%;
        height: 100%;
        transition: width .3s;
        // 聊天列表
        .chat-list{
          padding: 22px 15px;
          background-color: var(--chat-list-bg-color);
          //height: calc(100% - 40px);
          height: calc(100% - var(--chat-input-height-pc));
          overflow: auto;
          &::-webkit-scrollbar {
            width: 0;
            height: 0;
            scrollbar-width: none;
            scrollbar-height: none;
          }

          .chat-item{
            display: flex;
            margin-top: 35px;
            &:first-child{
              //margin-top: 0;
            }
            //&:last-child{
            //  margin-bottom: 40px;
            //}

            &-content{
              position: relative;
              max-width: calc(100% - 150px);

              &:hover {
                .chat-item-content-time{
                  visibility: visible;
                }
                .chat-item-content-actions{
                  visibility: visible;
                }
              }
              &-text{
                padding: 10px 11px;
                margin: 10px 8px 0;
                border-radius: 4px;
                background-color: var(--chat-content-bg-color);
                word-break: break-word;
                pre{
                  white-space: pre-wrap;
                }
                &.chat-item-content-text-error{
                  background-color: var(--chat-content-bg-error-color);
                }
              }
              &-time{
                font-size: 12px;
                visibility: hidden;
                position: absolute;
                top: -10px;
                left: 10px;
                color: var(--gray-title-color);
                min-width: 120px;
                &:hover{
                  visibility: visible;
                }
              }
              &-actions{
                display: flex;
                visibility: hidden;
                position: absolute;
                bottom: -5px;
                right: $action-offset;
                //background-color: var(--chat-content-bg-color);
                padding: 4px 6px;
                border-radius: 4px;
                align-items: center;
                //box-shadow: 0px 0px 1px 0px #454545;
                font-size: 12px;
                //width: 100%;

                &:hover{
                  visibility: visible;
                }

                .chat-action-icon{
                  margin: 0 1px;
                  font-size: 22px;
                  padding: 1px 1px;
                  cursor: pointer;
                  border-radius: 4px;
                  color: var(--gray-title-color);
                  &:hover{
                    color: var(--color-primary);
                  }
                }
              }
            }
            &.chat-item-self{
              flex-direction: row-reverse;
              .chat-item-content-text{
                background-color: var(--chat-content-self-bg-color);
                //border: 1px solid #c3e6cb;
              }
              .chat-item-content-time{
                right: 10px;
                left: unset;
                white-space: nowrap;
              }
              .chat-item-content-actions{
                left: $action-offset;
                right: unset;
              }
            }
          }

          // 空页面
          .empty-chat{
            padding: 18px 60px;
            background-color: var(--chat-list-bg-color);
            height: 100%;
            text-align: center;

            &-avatar{
              display: flex;
              justify-content: center;
              align-items: center;
              .avatar-img{
                width: 40px;
                height: 40px;
                border-radius: 50%;
              }
            }
            &-title{
              font-size: 30px;
              margin-top: 10px;
            }
            &-description{
              font-size: 16px;
              margin: 10px 0;
            }
            &-tip{
              color: var(--gray-text-color);
              font-size: 13px;
            }
            .random-btn{
              display: flex;
              justify-content: flex-end;
              align-items: center;
              margin-top: 10px;
              padding-right: 40px;
            }
            .command-examples-grid{
              display: grid;
              grid-template-columns: 1fr 1fr;
              grid-gap: 20px;
              padding: 20px 40px;
              margin-top: 6px;
            }
            .command-examples-flex{
              display: flex;
              //flex-direction: column;
              flex-wrap: wrap;
              justify-content: center;
              align-items: center;
              padding: 20px 40px;
              margin-top: 6px;
              .command-example-item{
                margin: 0 10px 10px
              }
            }
            .command-example-item{
              border-radius: 10px;
              background-color: var(--app-bg-color);
              text-align: left;
              padding: 12px 18px;
              cursor: pointer;
              &:hover{
                background-color: var(--app-active-bg-color);
              }
              &-title{
                display: flex;
                align-items: center;
                margin-bottom: 6px;
                .random-icon{
                  width: 20px;
                  height: 20px;
                  margin-right: 8px;
                  background-color: var(--gray-text-color);
                  border-radius: 5px;
                }
                .title{
                  font-weight: bold;
                  color: var(--gray-title-color);
                }
              }
            }
          }
        }
        .chat-footer{
          height: var(--chat-input-height-pc);
          border-top: 1px solid var(--chat-footer-border-color);
          background-color: var(--chat-footer-bg-color);
          .move-footer-line{
            height: 3px;
            cursor: s-resize;
          }
          .chat-toolbar{
            height: 46px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            &-left{
              height: 100%;
              display: flex;
              align-items: center;
            }
            &-right{
              height: 100%;
              display: flex;
              align-items: center;
            }
            .chat-toolbar-icon{
              font-size: 22px;
              margin: 0 15px;
              padding: 4px;
            }
          }
          .chat-input{
            .chat-input-textarea{
              height: 110px;
              resize: none;
              outline: none;
              border: unset;
              box-shadow: none;
              &::-webkit-input-placeholder{
                color:#777;
              }
            }
          }
          .chat-actions{
            display: flex;
            justify-content: flex-end;
            padding: 2px 7px 0 0;
            .send-part{
              display: flex;
              align-items: center;
              .send-type-tip{
                font-size: 12px;
                color: #999;
                margin-right: 10px;
              }
              .send-btn{
                width: auto;
                height: 30px;
                padding: 3px 15px;
                .send-icon{
                  font-size: 22px;
                }
              }
            }
          }

          // 测试按钮
          .test-part{
            flex: 1;
            padding-left: 20px;
          }
        }
      }
      &-right{
        transition: width .3s;
        height: 100%;
        //background-color: lightcyan;
        //color: #000;
        overflow: hidden;
        white-space: nowrap;
        border-left: 1px solid var(--border-color);
      }
    }
  }
}

.create-chat-modal{
  padding: 40px 20px 30px;
  text-align: center;
  .create-chat-title{
    font-size: 32px;
    font-weight: bolder;
  }
  .create-chat-sub-title{
    margin: 10px 0 8px;
  }
  .create-chat-select{
    margin: 40px 0 30px;
    &-btn{
      margin: 0 10px;
    }
  }
  .recommend-box{
    .recommend-title{
      font-size: 16px;
      padding: 0 0 10px;
      border-bottom: 1px solid var(--border-color);
    }
    .recommend-list{
      padding: 20px;
      display: flex;
      flex-wrap: wrap;
      align-content: flex-start;
      justify-content: center;
      .recommend-item{
        display: flex;
        align-items: center;
        align-content: center;
        padding: 10px 20px;
        border-radius: 6px;
        background-color: var(--app-bg-color);
        margin: 10px;
        font-size: 14px;
        cursor: pointer;
        &:hover{
          color: var(--emphasize-color);
          background-color: var(--app-active-bg-color);
        }
        &-avatar{
          width: 30px;
          height: 30px;
          border-radius: 2px;
          margin-right: 8px;
        }
        &-name{}
      }
    }
  }
}

.edit-dialog-name-modal{
  padding: 30px 20px 20px;
  .edit-input{}
}

.command-center{
  padding: 0 10px;
  height: 100%;
  $header-height: 50px;
  $search-height: 60px;
  $tags-height: 30px;
  &-header{
    display: flex;
    justify-content: center;
    align-items: center;
    height: $header-height;
    position: relative;
    .center-title{
      flex: 1;
      text-align: center;
      font-size: 16px;
      font-weight: bold;
    }
    .close-icon{
      position: absolute;
      right: 6px;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      cursor: pointer;
      color: var(--gray-text-color);
      &:hover{
        color: var(--color-primary);
        font-weight: bold;
      }
    }
  }
  &-search{
    display: flex;
    align-items: center;
    justify-content: center;
    height: $search-height;
  }
  &-tags{
    height: $tags-height;
    display: flex;
    //flex-wrap: wrap;
    align-items: center;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 0;
      height: 0;
      scrollbar-width: none;
      scrollbar-height: none;
    }
    .search-tag{
      margin: 3px 2px;
    }
  }
  .command-list{
    height: calc(100% - #{$header-height} - #{$search-height} - #{$tags-height});
    padding: 20px 0;
    overflow: auto;
    .command-item{
      //background-color: var(--app-bg-color);
      border-radius: 4px;
      padding: 8px 14px;
      display: flex;
      align-items: center;
      //width: 31.3%;
      //height: 150px;
      //box-shadow: 0 0 2px 0 #e2e2e2;
      margin: 10px 0;
      cursor: pointer;
      &:hover{
        background-color: var(--app-active-bg-color);
      }

      &:first-child{
        margin-top: 0;
      }
      &:last-child{
        margin-bottom: 0;
      }

      &-icon{
        width: 30px;
        height: 30px;
        border-radius: 5px;
        margin-right: 10px;
        background-color: var(--gray-text-color);
      }

      &-content{
        //width: calc(100% - 40px);
        .name{}
        .description{
          color: var(--gray-title-color);
          font-size: 13px;
        }
      }
    }
  }
  .command-empty{
    margin-top: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    width: 100%;
    color: var(--gray-text-color);
    &-icon{
      font-size: 40px;
      margin-bottom: 20px;
    }
  }
}

// 发送方式弹出气泡
.send-type-list{
  .send-type-item{
    padding: 3px 8px 3px 30px;
    $primary-color: var(--color-primary);
    position: relative;
    cursor: pointer;
    &-active{
      color: var(--color-primary);
      &:after{
        content: '';
        position: absolute;
        background-color: var(--color-primary);
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        opacity: 0.2;
        border-radius: 6px;
      }

      &-icon{
        position: absolute;
        top: 50%;
        left: 12px;
        transform: translateY(-50%);
        font-size: 12px;
      }
    }
  }
}

// 对话列表操作pop
.conversation-action-popover{
  .ant-popover-inner{
    padding: 4px 0;
  }
  .group-item-action-popover{
    .action-item{
      display: flex;
      align-items: center;
      padding: 6px 10px;
      border-radius: 5px;
      cursor: pointer;
      &-icon{
        margin-right: 8px;
      }
      &:hover{
        background-color: var(--app-bg-color);
      }
    }
  }
}

.chat-avatar{
  flex-shrink: 0;
}

.link-text{
  background-color: transparent;
  color: var(--link-color);
  cursor: pointer;
  &:hover{
    text-decoration: underline;
  }
}

.setting-pop-content{
  width: 400px;
  padding-top: 6px;
  .setting-pop-item{
    display: flex;
    align-items: center;
    margin: 10px 0;
    &-label{
      width: 100px;
    }
    &-value{
      flex: 1;
    }
  }
}
